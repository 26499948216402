var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Show")]),_c('label',[_vm._v("entries")])]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)])],1)],1)]),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[[_c('b-table',{ref:"refUserListTable",staticStyle:{"text-align":"center"},attrs:{"per-page":_vm.perPage,"show-empty":"","busy":_vm.loading,"small":"","responsive":"","striped":"","hover":"","items":_vm.orders,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('loader')],1)]},proxy:true},{key:"cell(orderNumber)",fn:function(data){return [_c('b-link',{attrs:{"to":{
              name: 'orderDetails',
              params: {
                id: data.item.id,
              },
            }}},[_vm._v(" "+_vm._s(data.item.orderNumber)+" ")])]}},{key:"cell(orderStatus)",fn:function(data){return [_c('b-badge',{staticClass:"text-capitalize",staticStyle:{"min-width":"140px","max-width":"180px"},attrs:{"pill":"","variant":("light-" + (_vm.resolveUserStatusVariant(
              data.item.orderStatus
            )))}},[_vm._v(" "+_vm._s(_vm.orderStatusDict[data.item.orderStatus])+" ")])]}},{key:"cell(paymentStatus)",fn:function(data){return [_c('b-button',{attrs:{"variant":".btn-flat-dark"}},[_vm._v(" "+_vm._s(data.item.paymentStatus == 0 ? "INCOMPLETE" : data.item.paymentStatus == 100 ? "PAID" : data.item.paymentStatus == 300 ? "REFUNDED" : data.item.paymentStatus == 90 ? "PARTIALLY_REFUNDED" : data.item.paymentStatus == 50 ? "AWAITING_PAYMENT" : "CANCELLED")+" ")])]}},{key:"cell(trackingNumber)",fn:function(data){return [_c('b-button',{staticStyle:{"margin-left":"10px"},attrs:{"variant":".btn-flat-dark"}},[_vm._v(" "+_vm._s(data.item.trackingNumber)+" ")])]}},{key:"cell(orderTotal)",fn:function(data){return [_c('b-button',{staticStyle:{"margin-left":"10px"},attrs:{"variant":".btn-flat-dark"}},[_vm._v(" "+_vm._s(data.item.cartTotal)+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"variant":".btn-flat-dark","to":{
              name: 'orderDetails',
              params: {
                id: data.item.id,
              },
            }}},[_c('external-link-icon',{staticClass:"custom-class text-primary",attrs:{"size":"1.5x"}})],1)]}}])})],_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-start\n          ",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.selectedRecords)+" to "+_vm._s(_vm.skip + 10)+" of "+_vm._s(_vm.rows)+" entries")])]),_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-end\n          ",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"disabled":_vm.loading,"total-rows":_vm.rows,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }